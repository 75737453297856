import React, { useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SliderList = ({ cansList, onCanSelect, hasData }) => {

    let sliderRef = null;

    const [currentSlide, setcurrentSlide] = useState(0);

    const handleKoop = () => {
        let selected_can = cansList[currentSlide];
        onCanSelect(selected_can);
    }
    const handlePrevSlider = () => {
        let x = currentSlide - 1;
        if (x < 0) {
            x = 6;
        }
        sliderRef.slickGoTo(x);
    };

    const handleNextSlider = () => {
        let x = currentSlide + 1;
        if (x > 6) {
            x = 0;
        }
        sliderRef.slickGoTo(x);
    };

    const afterChangeHandler = (currentSlide) => {
        setcurrentSlide(currentSlide);
    }

    var slider_settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: null,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    if ((cansList === undefined) || (cansList.length === 0)) {
        return (<div>Moment.... </div>);
    }

    const lijstje = cansList;

    function createMarkup(dangerous_html) {
        return { __html: dangerous_html }
    }

    function priceBeforeDecimal(price) {
        let n = price / 100;
        let whole = Math.floor(n);      // 1
        return whole;
    }

    function priceAfterDecimal(price) {
      let n = price / 100;
      let whole = Math.floor(n);      // 1
      let fraction = n - whole; // .25
      let decimalpart = parseNumberCustom(fraction);
      if (decimalpart==="0") {
          return "00";
      } else {
        return decimalpart;
      }
    }

    function KoopButton(props) {
        if (props.selectedcanvoorraad.stock>0) {
            return (
                <div className="slider-button-midden" onClick={() => handleKoop()} >Koop</div>
            )
        } else {
            return (
                <div className="slider-button-midden" >Geen Voorraad</div>
            )
        }
    }

    function parseNumberCustom(fract) {
        let number_string = fract.toString(); 
        var new_number = number_string.indexOf('.') >= 1 ? number_string.split('.')[1] : number_string;
        console.log('Before==>' + number_string + ', After==>' + new_number);
        return new_number;
    }

    const renderedList = lijstje.map((can) => {
        return (
            <React.Fragment key={can.uniqueID.toString()}>
                <br />
                <div className="fons-blik-item " >
                    <div className="circle-white" >
                        <img src={can.imageURL} className="can" alt="can" />
                        <div className="can-text">
                            <div dangerouslySetInnerHTML={createMarkup(can.description)} />
                        </div>
                        <div className="pricing">
                         
                            €{priceBeforeDecimal(can.price)},<div className="can-text-smal">{priceAfterDecimal(can.price)}<br />
                                250ml<br />
                                {can.kcal}Kcal<br />
                            </div>
                        </div>
                    </div>
                    <div className="circle-shadow" style={{ backgroundColor: "rgb(0, 47, 101)" }}></div>
                </div>
            </React.Fragment>
        );
    });


    return (
        <div>
            <Slider ref={(Slider) => { sliderRef = Slider }}  {...slider_settings} afterChange={afterChangeHandler} >
                {renderedList}
            </Slider>
            <br />
            <br />
            <div className="buttoncontainer">
                <div style={{ marginRight: "15%" }} className="triangle-left" onClick={() => handlePrevSlider()}></div>
                
                <KoopButton onClick={handleKoop} selectedcanvoorraad={cansList[currentSlide]}/>

                <div style={{ marginLeft: "15%" }} className="triangle-right" onClick={() => handleNextSlider()}></div>
            </div>


        </div>
    );

}
export default SliderList;
