import React, { useEffect } from 'react';
import { useGlobalState } from "../useGlobalState";
import axiosGetShafts from 'axios';
import { Container, Card, Button, Spinner } from 'react-bootstrap';

const Koop = (props) => {

  const [state, dispatch] = useGlobalState();

  const onLinkClickTikkie = e => {
    window.open(state.paymentRequestLink, '_self', '', 'true');
  }

  const onLinkClickOmniKassa = e => {
    window.open(state.paymentRequestLinkOmnikassa.redirectUrl, '_self', '', 'true');        
  }


  useEffect(() => {
    async function fetchPaymentRequestOmnikassa(selected_koop_can) {
      console.log("omnikassa", props.location.state.selected_koop_can, state.kortingsCode);
      let omnikassa = "https://functionappraborok20200327093637.azurewebsites.net/api/FunctionRokOrder";

      await axiosGetShafts.post(
        omnikassa, { 
          "timestamp": "2017-02-06T08:32:51.759+01:00", //wordt in azure function goed gezet.
          "merchantOrderId": "eehmmm", //wat moet dit worden?
          "merchantReturnURL": "http://rok.evytom.com/omnikassa",   //wordt in azure function overgenomen
          "currency" : "EU",
          "amount": selected_koop_can.price
        } )
        .then(function (response) {
          console.log("Omnikassa PR Request", response.data);
          dispatch({
            type: 'FETCH_PAYMENTREQUEST_OMNIKASSA',
            payload: response.data
          }); //voor de iis node met schachten, voor azure zonder
          dispatch({
            type: 'SET_SELECTEDCAN',
            payload: selected_koop_can
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    fetchPaymentRequestOmnikassa(props.location.state.selected_koop_can);
  }, [props.location.state.selected_koop_can, state.kortingsCode, dispatch]);

  useEffect(() => {
    async function fetchPaymentRequest(selected_koop_can) {
      console.log("tikkie", props.location.state.selected_koop_can, state.kortingsCode);
      //let tikkieazure = 'https://softwaartikkiecallback.azurewebsites.net/api/TikkiePaymentRequestGUID';
      let tikkieazure = 'https://tikkietest.azurewebsites.net/api/TikkiePaymentRequestGUID';      

      await axiosGetShafts.post(
        tikkieazure, {
        "automaat": selected_koop_can.guid,
        "schacht": selected_koop_can.column,
        "discountcode" : state.kortingsCode
      })
        .then(function (response) {
          console.log("Tikkie PR Request", response.data);
          dispatch({
            type: 'FETCH_PAYMENTREQUEST',
            payload: response.data
          }); //voor de iis node met schachten, voor azure zonder
          dispatch({
            type: 'SET_SELECTEDCAN',
            payload: selected_koop_can
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    fetchPaymentRequest(props.location.state.selected_koop_can);
  }, [props.location.state.selected_koop_can, state.kortingsCode, dispatch]);


  function createMarkup(dangerous_html) {
    return { __html: dangerous_html }
  }

  function priceBeforeDecimal(price) {
    let n = price / 100;
    let whole = Math.floor(n);      // 1
    return whole;
  }

  function priceAfterDecimal(price) {
    let n = price / 100;
    let whole = Math.floor(n);      // 1
    let fraction = n - whole; // .25
    let decimalpart = parseNumberCustom(fraction);
    if (decimalpart==="0") {
      return "00";
    } else {
      return decimalpart;
    }
  }

  function parseNumberCustom(fract) {
    let number_string = fract.toString();
    var new_number = number_string.indexOf('.') >= 1 ? number_string.split('.')[1] : number_string;
    console.log('Before==>' + number_string + ', After==>' + new_number);
    return new_number;
  }


  if (state.selectedCan === undefined) {
    return (
      <React.Fragment>
        <div className="loadertje">
          <Spinner animation="border" role="status" variant="light" className="loadertje" >
            <span className="sr-only" style={{ "color": "#000000" }}>Loading...</span>
          </Spinner>
        </div>
      </React.Fragment>
    );
  }
  return (

    <Container style={{ backgroundColor: "#00b5d5", height: "100%" }} >
      <div>
        <div>
          <Card style={{ backgroundColor: "#00b5d5", border: 3, marginTop: "0%" }}>
            <div className="float-right">
              <Card.Img className="float-right" style={{ width: "60%", align: "right" }} src="fridgecan-logo1.png" alt="logo" />
            </div>
            <Card.Body>
              <div>
                <div><label className="lbl label_dark">Scan</label></div>
                <div><label className="lbl label_dark">Choose</label></div>
                <div><label className="lbl label_selected">Refresh</label></div>
              </div>
              <br />
              <div className="fons-blik-item " key={state.selectedCan.uniqueID.toString()}>
                <div className="circle-white" >
                  <img src={state.selectedCan.imageURL} className="can" alt="can" />
                  <div className="can-text">
                    <div dangerouslySetInnerHTML={createMarkup(state.selectedCan.description)} />
                  </div>
                  <div className="pricing">
                    
                    €{priceBeforeDecimal(state.selectedCan.price)},<div className="can-text-smal">{priceAfterDecimal(state.selectedCan.price)}<br />
                      250ml<br />
                      {state.selectedCan.kcal}Kcal<br />
                    </div>
                  </div>
                </div>
                <div className="circle-shadow" style={{ backgroundColor: "rgb(0, 47, 101)" }}></div>
              </div>
            </Card.Body>
            <br />
            <br />
            <Button onClick={onLinkClickTikkie} className="button-pay" style={{ height: "50px", background: "#ffffff", color: "#003f5b" }}>Nu betalen</Button>
            <Button onClick={onLinkClickOmniKassa} className="button-pay" style={{ height: "50px", background: "#ffffff", color: "#003f5b" }}></Button>
          </Card>
        </div>
      </div>
    </Container>
  );
};

export default Koop;
