
function reducer(state, action) {
    switch (action.type) {

      case 'SET_SELECTEDCAN':
        state = {...state};
        state.selectedCan = action.payload;
        return state;

      case "SET_FRIDGECANHASDATA":
          state = {...state};
          state.hasFridgeCanData = action.payload;
          return state;

        case "FETCH_PAYMENTREQUEST":
            state = {...state};
            state.paymentRequestLink = action.payload;
          return state;

          case "FETCH_PAYMENTREQUEST_OMNIKASSA":
            state = {...state};
            state.paymentRequestLinkOmnikassa = action.payload;
          return state;
          
        case "SET_AUTOMAATNUMMER":
          state  = {...state};
          state.automaatNummer = action.payload;
          return state;

       case "SET_KORTINGSCODE":
            state  = {...state};
            state.kortingsCode = action.payload;
            return state;
  
        case "SET_KORTINGSCODEFOUT":
            state  = {...state};
            state.kortingsCodeFout = action.payload;
            return state;
                        
        case "CMDLINE":
          return state = "/tab1";

        case "GETFRIDGECAN":
          return [...state, {"can" : 123}];
          
        case "FETCH_FRIDGECANSHAFT":
              if (action.payload.schachten !== undefined)  {
                  state = {...state};
                  if (action.payload.schachten.length > 0) {
                    state.schachten = action.payload.schachten; 
                  } else {
                    state.schachten = []
                  } 
            }
            return state;
      default:
        return state
    }
  }
  export default reducer;