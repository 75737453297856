import { useGlobalState } from "../useGlobalState"
import React from 'react';
import { Container, Form, Button, Card, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const InvoerPage = props => {

    const [state, dispatch] = useGlobalState()
    const history = useHistory();
    let localAutomaatnummer = "";
    //localAutomaatnummer = "32768";

    if (state.automaatNummer === undefined) {
        dispatch({ type: 'SET_AUTOMAATNUMMER', payload: "" });
    }
    
    if (state.kortingsCode === undefined) {
        dispatch({ type: 'SET_KORTINGSCODE', payload: "" });
    }

    function handleAutomaatNummerValue(event) {
        localAutomaatnummer = event.target.value;
    }

    const onLinkClick = e => {
        dispatch({ type: 'SET_AUTOMAATNUMMER', payload: localAutomaatnummer });
        history.push('/kies');
    }

    return (
        <Container style={{ backgroundColor: "#00b5d5", height: "100%" }} >
            <div>
                <div>
                    <Card style={{ backgroundColor: "#00b5d5", border: 3, marginTop: "0%" }}>
                        <div className="float-right">
                            <Card.Img className="float-right" style={{ width: "60%", align: "right" }} src="fridgecan-logo1.png" alt="logo" />
                        </div>
                        <Card.Body>
                            <div>
                                <div><label className="lbl label_selected">Scan</label></div>
                                <div><label className="lbl label_dark">Choose</label></div>
                                <div><label className="lbl label_dark">Refresh</label></div>
                            </div>
                            <br />
                            <div className="label_selected" style={{ "width": "80%" }}>
                                Scan de QRcode op de koelkast om FridgeCan te activeren
                            </div>
                            <br />
                            <br />
                            <br />
                            <Form.Label className="label_selected">Of voer de code in</Form.Label>
                            <Row>
                                <Col xs="8">
                                    <Form.Control size="lg" style={{height:"50px"}} type="text" placeholder="Voer de code in" defaultValue={localAutomaatnummer} onChange={(e) => handleAutomaatNummerValue(e)} />
                                </Col>
                                <Col xs="1">
                                    <Button onClick={onLinkClick} style={{height:"50px", background:"#ffffff", color:"#003f5b"}}>Volgende</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </Container>
    );

}
export default InvoerPage;
