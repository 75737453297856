
//import React, { useEffect } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
//import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

function usePageViews() {
    let location = useLocation();
    const parsedCommandLine = queryString.parse(location.search);
    return parsedCommandLine;
}

let commandlineparameters = {"order_id" : "x", "signature": "x", "status":"x"}

const Omni = (props) => {
 //   const history = useHistory();

    commandlineparameters = usePageViews();

      
    return (

        <Container style={{ backgroundColor: "#00b5d5", height: "100%" }} >
          <div>
            <br/>
            <label>OrderID:</label>
            {commandlineparameters.order_id}
            <br/>
            <label>Signature:</label>
            {commandlineparameters.signature}
              <br/>
              <label>Status:</label>
              {commandlineparameters.status}
              <br/>
          </div>
        </Container>
      );
    }
    export default Omni;
    