import React, { useEffect } from 'react';
import { useGlobalState } from "../useGlobalState";
import axiosGetShafts from 'axios';
import SliderList from '../components/SliderList';
import { useHistory } from 'react-router-dom';
import { Container, Form, Card, Row, Col } from 'react-bootstrap';
import Popup from "reactjs-popup";
import { FaThumbsUp } from 'react-icons/fa';

const Kies = (props) => {
  let localKortingscode = "";
  const [state, dispatch] = useGlobalState();
  const history = useHistory();

  const onCanSelect = can => {
    history.push({ pathname: '/koop', search: '', state: { selected_koop_can: can, localKortingscode } })
  }

  function handleKortingscodeValue(event) {
    localKortingscode = event.target.value;
  }

  // function KortingButton(props) {
  //   //if (props.selectedcanvoorraad.stock>0) {
  //       return (
  //           <div className="slider-button-midden korting-button" onClick={() => handleKorting()} >Kortingscode</div>
  //       )
  //   //} else {
  //     //  return (
  //       //    <div className="slider-button-midden" >Geen Voorraad</div>
  //       //)
  //   //}
  // }

  const PopupExample = (props) => (
     <Popup trigger={<div className="float-left" style={{visibility: props.toonkortingicon===true ? "visible": "hidden" }}><FaThumbsUp/></div>} position="bottom center" 
     
     >
     {close => (
       <div className="ZZZmodal">
         {/* <a href className="close" onClick={close}>
         &times;
         </a> */}
         <button className="button" onClick={() => { close();}}>&times;</button>
         <div className="header"> Voer uw kortings code in: </div>
         <div className="content">
           {" "}
           <Container>
             <Row>
               <Col lg={8}>
                  <Form.Control size="lg" style={{height:"35px"}} type="text" placeholder="Voer de code in" defaultValue={localKortingscode} onChange={(e) => handleKortingscodeValue(e)} />
               </Col>
             </Row>        
             <button className="button" onClick={() => {console.log("modal closed "); handleKorting(); close();}}>Bevestigen</button>
           </Container>
         </div>
         <div className="actions">
         </div>
       </div>
     )}
     </Popup>
);


  // const PopupModal1 = () => (
  //   <Popup trigger={<button className="button"> Open Modal </button>} modal >
  //   {close => (
  //     <div className="zzzmodal" >
  //       <a className="close" onClick={close}>
  //         &times;
  //       </a>
  //       <div className="header"> Modal Title </div>
  //       <div className="content">
  //         {" "}
  //         Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum.
  //         Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates
  //         delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos?
  //         <br />
  //         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit
  //         commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae
  //         explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?
  //       </div>
  //       <div className="actions">
  
  //         <button
  //           className="button"
  //           onClick={() => {
  //             console.log("modal closed ");
  //             close();
  //           }}
  //         >
  //           close modal
  //         </button>
  //       </div>
  //     </div>
  //   )}
  // </Popup>
  // );

  const handleKorting =  (props) => {
     fetchData2();
  }

  async function fetchData2() {
    //let tikkieazure = 'https://softwaartikkiecallback.azurewebsites.net/api/getFridgeCanShafts';
    let tikkieazure = 'https://tikkietest.azurewebsites.net/api/getFridgeCanShafts';
    await axiosGetShafts.post(
      tikkieazure, {
      "automaat": state.automaatNummer, 
      "discountcode" : localKortingscode
    }
    )
      .then(function (response) {
        if (response.data.schachten.length === 0) {
          //state.kortingsCodeFout
          dispatch({ type: 'SET_KORTINGSCODEFOUT', payload: true });
          dispatch({ type: 'SET_KORTINGSCODE', payload: "" });
        } else {
          dispatch({ type: 'SET_KORTINGSCODE', payload: localKortingscode });
          dispatch({ type: 'SET_KORTINGSCODEFOUT', payload: false });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }




  useEffect(() => {
    async function fetchData() {
      //let tikkieazure = 'https://softwaartikkiecallback.azurewebsites.net/api/getFridgeCanShafts';
      let tikkieazure = 'https://tikkietest.azurewebsites.net/api/getFridgeCanShafts';      
      await axiosGetShafts.post(
        tikkieazure, {
        "automaat": state.automaatNummer, 
        "discountcode" : state.kortingsCode
      }
      )
        .then(function (response) {
          dispatch({
            type: 'SET_FRIDGECANHASDATA',
            payload: (response.data.schachten.length !== 0)
          });
          dispatch({
            type: 'FETCH_FRIDGECANSHAFT',
            payload: response.data
          }); //voor de iis node met schachten, voor azure zonder
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    fetchData();
  }, [state.automaatNummer, state.kortingsCode, state.kortingsCodeFout, dispatch]);




  return (

    <Container style={{ backgroundColor: "#00b5d5", height: "100%" }} >
      <div>
        <div>
          <Card style={{ backgroundColor: "#00b5d5", border: 3, marginTop: "0%" }}>
            <PopupExample toonkortingicon={(state.kortingsCode==="" || (state.kortingsCode!=="" && state.kortingsCodeFout===true) )}></PopupExample>
            <div className="float-right">
              <Card.Img className="float-right" style={{ width: "60%", align: "right" }} src="fridgecan-logo1.png" alt="logo" />
            </div>
            {/* <Card.Body> */}
            {(state.kortingsCode === "") ? null : <h5 className="label_selected">Uw kortings code: {state.kortingsCode}</h5>}
            {(state.kortingsCodeFout === false) ? null : <h5 className="label_selected">Korting code fout: {localKortingscode}</h5>}
              <div>
                <div><label className="lbl label_dark">Scan</label></div>
                <div><label className="lbl label_selected">Choose</label></div>
                <div><label className="lbl label_dark">Refresh</label></div>
              </div>
              <SliderList cansList={state.schachten} onCanSelect={onCanSelect} hasData={state.hasFridgeCanData} />
            {/* </Card.Body> */}
          </Card>
        </div>
      </div>
    </Container>
  );
}
export default Kies;
