import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect, Route, useLocation, Switch } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from "./useGlobalState"
import queryString from 'query-string';
import InvoerPage from './pages/InvoerPage';
import Kies from './pages/Kies';
import Koop from './pages/Koop';
import Test from './pages/Test';
import Omni from './pages/Omni';

import reducer from './reducers';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './theme/variables.css';

let initialState = [];

function usePageViews() {
  let location = useLocation();
  const parsedCommandLine = queryString.parse(location.search);
  return parsedCommandLine.automaat;
}

function App() {

  const history = useHistory();

  let commandlineAutomaatNummer = usePageViews();

  let routeEerstePage = "/invoer";
  initialState.cmdline = routeEerstePage;

  //commandlineAutomaatNummer = "32768";
  initialState.automaatNummer = commandlineAutomaatNummer;
  initialState.kortingsCode = "";
  initialState.kortingsCodeFout = false;
  initialState.schachten = [];
  initialState.hasFridgeCanData = false;
  initialState.withItemButton = true;
  if (commandlineAutomaatNummer !== undefined) {
    routeEerstePage = "/kies";
  }

  useEffect(() => {

    history.listen((newLocation, action) => {
      if (action === "PUSH") {
        if (
          newLocation.pathname !== this.currentPathname ||
          newLocation.search !== this.currentSearch
        ) {
          // Save new location
          this.currentPathname = newLocation.pathname;
          this.currentSearch = newLocation.search;

          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search
          });
        }
      } else {
        // Send user back if they try to navigate back
        history.go(1);
      }
    });
  }, [history]);

  return (
    <Provider reducer={reducer} initialState={initialState}>
      <Router>
        <Switch>
          <Route path="/invoer" component={InvoerPage} />
          <Route path="/kies" component={Kies} />
          <Route path="/omnikassa" component={Omni} />
          <Route path="/test" component={Test} />          
          <Route path="/koop" component={Koop} />
          <Route path="/" render={() => <Redirect to={routeEerstePage} />} exact={true} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App;
