import React from 'react';
import { Container } from 'react-bootstrap';

const Test = (props) => {
 return (
    <Container style={{ backgroundColor: "#00b5d5", height: "100%" }} >
      <div>
          Test page
      </div>
    </Container>
  );
}
export default Test;
